<template>
  <div>
    <DepartmentList></DepartmentList>
  </div>
</template>

<script>
import DepartmentList from './DepartmentList.vue'


export default {
  components: {
    DepartmentList
  },
  methods: {},
};
</script>

