<template>
  <vx-card title="Departments">
    <template slot="actions">
      <vs-button @click="editSelected({})">Add</vs-button>
      <EditDepartment v-if="selectedDepartment" :selectedDepartment="selectedDepartment" :openAddWindow="openAddWindow" @loadDepartments="loadDepartments"></EditDepartment>
    </template>
    <vs-table :sst="true" stripe :data="departments" v-if="departments.length">
      <template #thead>
        <vs-th sort-key="department">Department</vs-th>
        <vs-th class="width1"></vs-th>
      </template>
      <template>
        <vs-tr :key="i" v-for="(tr, i) in departments" :data="tr">
          <vs-td>
            {{ tr.clientDepartment }}
          </vs-td>
          <vs-td>
            <vs-button color="dark" @click="removeDepartment(tr.id)">Delete</vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
  </vx-card>
</template>
<script>
import EditDepartment from "./EditDepartment.vue";
import { departmentData } from "../../store/api/department";
import _ from "lodash";

export default {
  data() {
    return {
      departments: [],
      selectedDepartment: undefined,
      openAddWindow: true,
      noData: {
        title: "No Records Found",
      },
    };
  },
  components: {
    EditDepartment,
  },
  async created() {
    await this.loadDepartments();
  },
  methods: {
    async loadDepartments() {
      this.departments = [];
      this.departments = await departmentData.getAllDepartment();

      if (this.openAddWindow) {
        this.openAddWindow = !this.openAddWindow;
      }
    },
    async removeDepartment(id) {
      if (confirm("are you sure ? ")) {
        var result = await departmentData.deleteDepartment(id);
        await this.loadDepartments(result);
      }
    },

    async editSelected(tr) {
      this.selectedDepartment = tr;
      if (!this.openAddWindow) {
        this.openAddWindow = !this.openAddWindow;
      }
    },
  },
};
</script>
